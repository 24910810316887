/* //////////////////////////////////////////////FOOTER////////////////////////////////////////////////// */

@import url("../../../index.css");

footer {
  background-image: url(../../../assets/images/footer.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-height: 100vh;
  height: 100vh;
}

.footer .container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7rem;
  margin-bottom: 6%;
}

.footer__upper {
  /* border: red solid 1px; */
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
}

.footer__row {
  /* border: red solid 1px; */
  /* padding-left: 2rem; */
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.temp1 > .title1 {
  height: 102px;
  color: var(--color-white);
  font-family: var(--font-proximanova-light);
  text-align: left;
  font-size: 97px;
  text-transform: uppercase;
}

.temp2 > .smalltext1 {
  width: 80%;
  height: auto;
  color: var(--color-white);
  font-family: var(--font-proximanova-light);
  text-align: left;
  font-size: 17px;
  /* margin-right: 2rem; */
}

.footer__row .title2 {
  color: var(--color-white);
  font-family: var(--font-miracle);
  font-size: 97px;
  text-transform: uppercase;
  width: 100%;
  /* border: white solid 1px; */
  text-align: end;
}

.footer__row > .custom__btn {
  margin-right: 2rem;
  width: 300px;
}

.footer__row:nth-child(3) {
  justify-content: flex-end;
  align-items: center;
}

.temp {
  width: 269px;
}

.temp1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  /* border: white solid 1px; */
}

.temp2 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  /* border: white solid 1px; */
}

.footer__lower {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  /* border: green solid 1px; */
  height: 30vh;
}

.footer-bottom {
  padding-top: 10%;
  height: auto;
  display: flex;
  margin-left: 20%;
}

.row {
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  font-family: "ProximaNovaSemiBold";
  letter-spacing: 0.1rem;
  color: var(--color-white);
}

.footer__top {
  display: block;
  width: 200px;
  text-transform: uppercase;
  text-align: left;
  color: var(--color-white);
  font-family: var(--font-proximanova-semibold);
}

.footer__middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer__middle .nav__container {
  color: var(--color-white);
  padding: 0;
}

.footer__middle .nav__container a {
  color: var(--color-white);
  font-family: var(--font-proximanova-light);
  font-size: 15px;
}

.footer__middle span:first-child {
  border-right: #ffffff solid 1px;
  padding-right: 15px;
}

.footer__middle span a {
  text-decoration: none;
  color: var(--color-white);
}

.row span:nth-child(2) {
  padding-left: 15px;
}

.footer__bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer__bottom > div > span {
  font-family: var(--font-proximanova-light);
  font-size: 15px;
  color: var(--color-grey);
  text-align: left;
  letter-spacing: 0px;
}

.footer-bottom div:first-child span {
  width: 100px;
}

.footer__right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  bottom: 12%;
  right: 5%;
}

#go-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  padding-top: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

#go-up:hover {
  border: 1px solid var(--color-grey);
  background-color: white;
}

#go-up:hover svg path {
  fill: var(--color-grey);
}

.footer__left {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}

.footer__container__mobile {
  display: none;
}

.nav__container ul li {
  position: relative;
  transition: color 0.3s ease;
}

.nav__container ul li {
  position: relative;
  transition: color 0.3s ease;
}

.nav__container ul li:not(.nav__selected).white::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 1px;
  background-color: transparent;
  transition: width 0.3s ease;
}

.nav__container ul li:not(.nav__selected).white:hover::after {
  width: 100%;
  background-color: white;
}

/* For SEO  */

.address_in_footer a {
  text-decoration: none;
  color: var(--color-white);
  font-family: var(--font-proximanova-light);
  font-size: 15px;
}

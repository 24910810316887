.overlay {
  position: absolute;
  top: 10%;
  right: 10%;
}

.mobile-logo {
  position: absolute;
  top: 3rem;
  left: 0;
}

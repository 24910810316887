.about-us-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.about-us-carousel img {
  width: 100%;
  height: auto;
  display: none;
  opacity: 0;
}

.about-us-carousel img.active {
  display: block;
  opacity: 1;
}

.portfolio__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  height: 100%;
  max-width: 1200px;
  max-height: 100%;
}

.portfolio__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grid__item2,
.grid__item3,
.grid2__item1,
.grid2__item3 {
  height: 300px;
  grid-row: span 1;
}
.grid__item1,
.grid2__item2 {
  grid-row: span 2;
  height: 800px;
}

.portfolio__item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolio__item .title {
  font-family: var(--font-proximanova-semibold);
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: var(--color-black1);
  padding-top: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.portfolio__item .location {
  font-family: var(--font-proximanova-light);
  font-size: 18px;
  font-weight: 300px;
  text-align: left;
  color: var(--color-grey);
}

.portfolio__item .description {
  font-family: var(--font-proximanova-light);
  font-size: 20px;
  text-align: left;
  color: var(--color-black1);
  line-height: 27px;
  padding-top: 5%;
  height: 98px;
}

.portfolio__img:hover {
  transform: scale(1.05) rotateX(10deg);
}

.hide-cursor {
  cursor: none;
}

.custom-cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

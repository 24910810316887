.ProGalleryInfoItem1555825819__bgIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 28px;
  background-color: rgba(22, 22, 22, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProGalleryInfoItem1555825819__warpIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProGalleryInfoItem1555825819__bgIcon__mobile {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  background-color: rgba(22, 22, 22, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProGalleryInfoItem1555825819__warpIcon__mobile {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

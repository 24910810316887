.services__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.services__title {
  color: var(--color-white);
  font-family: var(--font-miracle);
  font-size: 90px;
  height: 155px;
  font-weight: normal;
  text-transform: uppercase;
}

.services__content {
  display: flex;
  width: 100%;
  height: 789px;
}

.services__left {
  height: 100%;
  margin-right: 1rem;
}

.services__right {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.service {
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--color-grey);
  border-bottom: 1px solid var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s;
}

.service__up {
  grid-column: 1; /* This item spans the first column */
  grid-row: 1; /* This item is in the first row */
}

.service__down {
  grid-column: 1; /* This item spans the first column */
  grid-row: 2; /* This item is in the second row */
}

.service__img {
  grid-column: 2; /* This item spans the second column */
  grid-row: 1 / span 2; /* This item spans both rows */
  display: flex;
  align-items: center;
  justify-content: center;
}

.service__up .service__num {
  font-family: var(--font-proximanova-light);
  font-size: 22px;
  color: var(--color-grey);
  padding-right: 1rem;
}

.service__up .service__title {
  text-transform: uppercase;
  font-family: var(--font-proximanova-semibold);
  color: var(--color-grey);
  font-size: 30px;
  letter-spacing: 5px;
  text-align: left;
}

.service__down .service__description {
  font-family: var(--font-proximanova-light);
  color: var(--color-grey);
  font-size: 16px;
  display: inline-block;
  width: 600px;
}

.service .service__container {
  padding: 2% 5% 2% 5%;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns of equal width */
  grid-template-rows: auto auto; /* Two rows with auto sizing */
  grid-gap: 10px; /* Adjust this value to add space between grid items */
}

.service:hover {
  background-color: white;
}

.service__container__active {
  display: none;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.service__num__active {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
  color: var(--color-black1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-miracle);
  font-size: 90px;
  width: 109px;
  margin-left: 2rem;
}

.service__title__active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
  color: var(--color-black1);
  font-family: var(--font-proximanova-semibold);
  font-size: 30px;
  text-transform: uppercase;
  height: auto;
}

.service__desc__active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
  color: var(--color-black1);
  font-family: var(--font-proximanova-light);
  font-size: 16px;
  width: 450px;
  height: 3rem;
  line-height: 20px;
}

.service__img__active {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 4;
}

@media (max-width: 768px) {
  .services__title {
    color: var(--color-white);
    font-family: var(--font-miracle);
    font-size: 20px;
    height: auto;
    font-weight: normal;
    text-transform: uppercase;
  }

  .services__content {
    display: flex;
    width: auto;
    height: auto;
  }

  .service__up {
    grid-column: 1; /* This item spans the first column */
    grid-row: 1; /* This item is in the first row */
  }

  .service__down {
    grid-column: 1; /* This item spans the first column */
    grid-row: 2; /* This item is in the second row */
  }

  .service__img {
    grid-column: 2; /* This item spans the second column */
    grid-row: 1 / span 2; /* This item spans both rows */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service__up .service__num {
    font-family: var(--font-proximanova-light);
    font-size: 22px;
    color: var(--color-grey);
    padding-right: 1rem;
  }

  .service__up .service__title {
    text-transform: uppercase;
    font-family: var(--font-proximanova-semibold);
    color: var(--color-grey);
    font-size: 30px;
    letter-spacing: 5px;
    text-align: left;
  }

  .service__down .service__description {
    font-family: var(--font-proximanova-light);
    color: var(--color-grey);
    font-size: 16px;
    display: inline-block;
    max-width: 260px;
  }

  .service .service__container {
    padding: 2% 5%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    grid-template-rows: auto auto; /* Two rows with auto sizing */
    grid-gap: 10px; /* Adjust this value to add space between grid items */
  }

  .service-mob:hover {
    background-color: white;
  }

  .service__container__active {
    display: none;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .service__num__active {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
    color: var(--color-black1);
    display: flex;
    align-items: start;
    justify-content: center;
    font-family: var(--font-miracle);
    font-size: 70px;
    width: auto;
    margin-left: 0;
    margin-top: 4rem;
  }

  .service__title__active {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
    color: var(--color-black1);
    font-family: var(--font-proximanova-semibold);
    font-size: 30px;
    text-transform: uppercase;
    max-height: auto;
  }

  .service__desc__active {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 3;
    color: var(--color-black1);
    font-family: var(--font-proximanova-light);
    font-size: 16px;
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 50%;
    line-height: 20px;
  }

  .service__img__active {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
  }

  .border-bottom-1 {
    border-bottom: 1px solid var(--color-grey);
  }

  .border-top-1 {
    border-top: 1px solid var(--color-grey);
  }
}

.preloader {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.preloader.show {
  opacity: 1;
}

.preloader.hide {
  opacity: 0;
}
.fullscreen {
  position: relative;
  height: 100vh;
  overflow: hidden;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.page-hero .fullscreen {
  height: auto;
  min-height: 100vh;
}

.owner-hero .fullscreen {
  height: calc(100vh - 3rem);
}

@media all and (orientation: landscape) {
  .landscape-image ~ .portrait-image {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

@media all and (orientation: portrait) {
  .portrait-image ~ .landscape-image {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

.fullscreen .wrap {
  margin: 0;
  z-index: 4;
  padding-top: 0;
  padding-bottom: 0;
}

.fullscreen .fixed-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.fullscreen .fixed-wrap.fixed {
  position: fixed;
}

.fullscreen .slideshow,
.fullscreen .slide-image {
  width: 100%;
  height: 100%;
}

.fullscreen .slide-image {
  position: relative;
  z-index: 0;
}

.fullscreen .slide-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 2;
}

.fullscreen a {
  color: #fff;
}

.slide-image + .slide-text,
.slide-text.over-image {
  color: #fff;
  background: radial-gradient(
    ellipse 50% 50%,
    rgba(18, 18, 18, 0.125),
    rgba(18, 18, 18, 0)
  );
}

.hero-btn {
  transition: background-color 0.3s, color 0.3s;
}

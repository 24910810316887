/* /////////////////////////////Landing Page Animation//////////////////////// */

.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  opacity: 1;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.landing-page.fade-out {
  opacity: 0;
  visibility: hidden;
}

.landing-logo {
  width: 200px;
  height: auto;
}

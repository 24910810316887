svg#right {
  height: 22px;
  width: 120px;
  opacity: 1;
  transition: 250ms all ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  path {
    transition: 250ms all ease;
    height: 100%;
  }
}

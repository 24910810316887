#service-hero {
  /* background-color: black; */
  padding-top: 15rem;
  height: 100vh;
  max-height: 100vh;
}

.col{
  height: 100%;
  max-height: 100vh;
}

#service-hero .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100vh;
  max-width: 100%;
  margin-top: 10rem;
}

#service-hero .row p {
  max-width: 455px;
  max-height: 221px;
  color: var(--color-grey);
  font-family: var(--font-proximanova-light);
  margin-bottom: 5rem;
}

.banner {
  position: absolute;
  top: 40%;
  left: 10%;
  display: flex;
  flex-direction: column;
  /* z-index: 1; */
}

.banner h1 {
  text-transform: uppercase;
  color: var(--color-grey);
  font-size: 70px;
  height: 65px;
}

.banner h1:first-child {
  font-family: var(--font-proximanova-light);
  width: 1151px;
}

.banner h1:last-child {
  font-family: var(--font-miracle);
  padding-left: 15%;
}
.header {
  z-index: 1000;
  position: absolute;
  display: block;
  width: 100%;
  top: 5rem;
}

@media (max-width: 1024px) {
  .header {
    top: 0;
  }
  
}

/* .header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  top: 5rem;
} */

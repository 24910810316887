@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.thank-you-message {
  text-align: center;
  padding: 2rem;
  border-radius: 0.25rem;
  color: var(--color-grey);
  animation: fadeIn 1s forwards;
}

.thank-you-message.fade-out {
  animation: fadeOut 1s forwards;
}

.thank-you-message h2 {
  margin-bottom: 1rem;
}

.thank-you-message p {
  margin-bottom: 0;
  font-size: 1.25rem;
}

#aboutUs-hero {
  padding-top: 20%;
}

#aboutUs-hero .container {
  display: flex;
  width: 100%;
  max-height: 100vh;
}

.hero__small__div {
  align-self: self-end;
  width: auto;
  /* max-height: 20%; */
  padding-right: 10%;
}
.hero__small__div h1 {
  max-width: 50%;
}

.hero__s__t {
  width: 219px;
  align-self: self-start;
}

.w-100 {
  width: 100%;
}

.hero__small__div:nth-child(2) {
  padding-top: 5rem;
}

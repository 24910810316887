#main {
  margin-top: 15rem;
  overflow: hidden;
}

.title {
  font-family: var(--font-miracle);
  font-size: 90px;
  color: var(--color-black1);
  text-align: center;
  text-transform: uppercase;
}

#main .container > .container {
  border-top: 1px solid var(--color-grey-bg);
  border-bottom: 1px solid var(--color-grey-bg);
  margin-bottom: 5rem;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  max-height: 100vh; /* Set max height to 100vh */
  border-right: 1px solid var(--color-grey-bg);
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
}

.services__menu {
  margin-top: 20%;
  padding: 0;
  height: 100%;
}

.services__menu > ul {
  list-style-type: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: var(--font-proximanova-light);
  padding-left: 0;
}

.services__menu__item {
  font-family: var(--font-proximanova-light);
  color: var(--color-grey1) !important;
  text-decoration: none;
}

.services__menu__item__selected {
  font-family: var(--font-proximanova-semibold);
  color: var(--color-black1) !important;
  text-decoration: underline;
}

.services__menu ul li {
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 1rem;
}

.description > p{
  font-family: var(--font-proximanova-light);
  font-size: 16px;
  color: var(--color-black1);
  /* max-width: 80%; */
}

.right {
  padding: 3rem 5rem 5rem 5rem;
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
  max-height: calc(100vh - 15rem);
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
}

.images img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
}

.black-bg {
  background-color: black;
}

.white-bg {
  background-color: white;
}

#root {
  transition: background-color 0.5s ease-in-out;
}

/* Define scrollbar styles */
.right::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle */
.right::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
}

/* Handle on hover */
.right::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}

/* Track */
.right::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle for Firefox on hover */
.right:hover {
  scrollbar-color: #555 #f1f1f1; /* Color of the scrollbar handle on hover for Firefox */
}

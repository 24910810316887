.testimanials-button {
  transition: background-color 0.2s ease;
  border: 1px solid var(--color-grey);
}

.testimanials-button:hover svg path {
  fill: black;
}

.hovered {
  background-color: white !important;
  border: 1px solid white;
}

@font-face {
  font-family: "ProximaNovaBold";
  src: url("/src/fonts/ProximaNovaBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaLight";
  src: url("/src/fonts/ProximaNovaLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaSemiBold";
  src: url("/src/fonts/ProximaNovaSemibold.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "MiraclePlacePersonalUse";
  src: url("/src/fonts/MiraclePersonalUse.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--font-miracle);
  font-size: var(--font-size);
  line-height: 1.5;
  background-color: var(--color-white);
  box-sizing: border-box;
  text-align: left;
  overflow-x: hidden;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  transition: background-color 1s ease;
  width: 100vw;
}

html {
  margin: auto;
}

:root {
  --color-black1: #000000;
  --color-black2: #030303;
  --color-grey: #808080;
  --color-grey1: #bbbbbb;
  --color-grey-bg: #c8c8c8;
  --color-white: #ffffff;
  --font-miracle: "MiraclePlacePersonalUse", sans-serif;
  --font-proximanova-light: "ProximaNovaLight", sans-serif;
  --font-proximanova-semibold: "ProximaNovaSemibold", sans-serif;
  --font-proximanova-bold: "ProximaNovaBold", sans-serif;
  --font-size: 16px;
}

.font-miracle {
  font-family: var(--font-miracle) !important;
}

.font-proximanova-light {
  font-family: var(--font-proximanova-light) !important;
}

.font-proximanova-semibold {
  font-family: var(--font-proximanova-semibold) !important;
}

.font-proximanova-bold {
  font-family: var(--font-proximanova-bold) !important;
}

.color-black1 {
  color: var(--color-black1) !important;
}

.color-black2 {
  color: var(--color-black2) !important;
}

.color-grey {
  color: var(--color-grey) !important;
}

.color-grey1 {
  color: var(--color-grey1) !important;
}

.color-grey-bg {
  color: var(--color-grey-bg) !important;
}

.color-white {
  color: var(--color-white) !important;
}

.bg-black1 {
  background-color: var(--color-black1) !important;
}

.my-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

p {
  line-height: 1.3;
  color: #000 !important;
}

ul > li {
  color: #000 !important;
}

#root {
  overflow-x: hidden;
}

/* ////////////////////////////////Project slider///////////////////////////// */
.slick-initialized .slick-slide {
  display: block;
  height: 46px;
}

.slick-slide > div {
  height: 100%;
}

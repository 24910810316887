/* a {
  text-decoration: underline !important;
  color: white !important;
  font-weight: 600 !important;
  transition-duration: 300ms;
} */

.zeal-digital a {
  text-decoration: underline !important;
  color: white !important;
  font-weight: 600 !important;
  transition-duration: 300ms;
}

.zeal-digital a:hover {
  opacity: 0.75;
}

.container-02 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

.container-02 .img {
  min-width: clamp(280px, 50%, 600px);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  aspect-ratio: 1/1;
}

.our-process-container {
  display: grid;
  grid-template-columns: 2fr 10fr;
}

.process-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  gap: 32px;
}

.process-decoration {
  display: grid;
  gap: 32px;
  margin-top: 2rem;
}
.process-decoration-step {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  align-items: center;
}
.process-decoration-step .decoration-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
}

.process-decoration-step .decoration-line {
  /* background-color: white;
  height: 1px; */
  border-bottom: 0px solid whitesmoke;
  border-left: 1px solid whitesmoke;
  flex-grow: 1;
}

#why-choose-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 32px;
  color: white;
}

#why-choose-container img {
  background-color: rgb(32, 32, 32);
  width: clamp(200px, 100%, 500px);
  aspect-ratio: 5/4;
}

.hero-section-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
}

.hero-section-grid > div:first-child {
  order: 2;
}

.hero-section-grid > div:first-child {
  order: 1;
}

#craftsmen-section {
  display: flex;
  flex-direction: column;
}

#craftsmen-section > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

#craftsmen-section > div > div {
  padding: 16px;
}

#craftsmen-section > div:nth-child(even) > div:first-child {
  order: 2;
}
#craftsmen-section > div:nth-child(even) > div:last-child {
  order: 1;
}

#craftsmen-section > div:nth-child(odd) > div:first-child {
  order: 2;
}
#craftsmen-section > div:nth-child(odd) > div:last-child {
  order: 1;
}

#craftsmen-section .img {
  height: clamp(400px, auto, 700px);
  background-color: rgb(32, 32, 32);
}

.zeal-components h1,
.zeal-components h2,
.zeal-components h3,
.zeal-components h4,
.zeal-components h5,
.zeal-components h6 {
  text-transform: capitalize !important;
  color: white !important;
}

.service-area-button {
  background-color: white;
  color: black;
  border: 1px solid transparent;
  flex-basis: 15%;
  display: grid;
  place-items: center;
  /* max-width: 50%; */
}

.call-us-button {
  background-color: white;
  color: black;
  font-size: 20px;
  max-width: 50%;
  border: 1px solid transparent;
  display: grid;
  place-items: center;
}

.call-us-button:hover {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  background-color: black;
  color: gray;
  border: 1px solid gray;
  box-shadow: 0 0 8px #ffffff54;
}

.service-area-button:hover {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  background-color: black;
  color: gray;
  border: 1px solid gray;
  box-shadow: 0 0 8px #ffffff54;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: rgb(20, 20, 20) !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 transparent !important;
}

.accordion-button.collapsed {
  color: #fff !important;
  background-color: rgb(20, 20, 20) !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 transparent !important;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: #000 !important;
  border: 0px solid #000 !important;
}

.zeal-components p {
  color: rgba(245, 245, 245, 0.829) !important;
}

.zeal-components p strong {
  color: white !important;
}
.zeal-components li {
  color: rgba(245, 245, 245, 0.829) !important;
}

.zeal-components li strong {
  color: white !important;
}

.mobile-grid-service-area {
  grid-template-columns: 1fr;
}

@media (min-width: 576px) {
  .mobile-grid-service-area {
    grid-template-columns: 1fr 1fr;
  }
}

.uni-padding-y {
  padding-block: 2rem !important;
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container-02 {
    grid-template-columns: 1fr 1fr;
  }
  .our-process-container {
    grid-template-columns: 1fr;
  }
  .process-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .process-decoration {
    grid-template-columns: repeat(4, 1fr);
  }
  .process-decoration-step {
    flex-direction: row;
  }
  .process-decoration-step .decoration-dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
  }

  .process-decoration-step .decoration-line {
    /* background-color: white;
    height: 1px; */
    border-bottom: 1px solid whitesmoke;
    flex-grow: 1;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #why-choose-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .hero-section-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-section-grid > div:first-child {
    order: 1;
  }

  .hero-section-grid > div:nth-child(2) {
    order: 2;
  }

  #craftsmen-section > div {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  #craftsmen-section > div:nth-child(odd) > div:first-child {
    order: 1;
  }
  #craftsmen-section > div:nth-child(odd) > div:last-child {
    order: 2;
  }
  .uni-padding-y {
    padding-block: 3rem !important;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

.overlay {
  position: absolute;
  top: 0;
  left: 10%;
}

.nav__container ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.nav__container li {
  margin: 0 0.5rem;
}

.nav__container a {
  text-decoration: none;
  font-family: var(--font-proximanova-light);
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.nav__selected {
  font-family: var(--font-proximanova-semibold);
  font-weight: bolder;
}

.logo {
  margin: 0 5rem;
  width: 30px;
  height: 30px;
}

#search-field {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: auto;
}
#search-field input {
  width: 0;
  height: 100%;
  border: none;
  outline: none;
  transition: 0.8s;
  background: transparent;
  font-family: var(--font-proximanova-light);
  font-size: 15px;
  padding: 5px;
  /* border: 1px solid red; */
}
#search-field:hover input {
  width: 160px;
  height: 100%;
  border: black solid 1px;
  /* padding: 5px; */
}

#search-field:hover a .fas {
  color: white;
  background-color: var(--color-black1);
  border: black solid 1px;
  padding: 5px;
}

.social__media {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social__media > li:first-child {
  margin-right: 0.1rem;
}

.social__media__icon {
  display: flex;
  align-items: center;
}

.black {
  color: var(--color-black1);
}

.white {
  color: var(--color-white);
}

.white-border-bottom {
  border-bottom: 1px solid var(--color-white);
}

.white-border {
  border: 1px solid var(--color-white);
}

.black-border-bottom {
  border-bottom: 1px solid var(--color-black1);
}

.black-border {
  border: 1px solid var(--color-black1);
}

.nav__container ul li {
  position: relative;
  transition: color 0.3s ease;
}

.nav__container ul li {
  position: relative;
  transition: color 0.3s ease;
}

.nav__container ul li:not(.nav__selected).white::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 1px;
  background-color: transparent;
  transition: width 0.3s ease;
}

.nav__container ul li:not(.nav__selected).white:hover::after {
  width: 100%;
  background-color: white;
}

.nav__container ul li:not(.nav__selected).black::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 1px;
  background-color: transparent;
  transition: width 0.3s ease;
}

.nav__container ul li:not(.nav__selected).black:hover::after {
  width: 100%;
  background-color: black;
}

/* ///////////////////////////////////////////////////////////////MOBILE SIDE BAR////////////////////////////////////////////////////////// */

@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.side-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  background-color: white;
  animation: fadeInOut 0.5s ease;
}

.side__nav_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.side__nav_container ul {
  margin: 0 1rem;
  padding: 0;
  width: 100%;
}

.side__nav_container > ul > li {
  list-style-type: none;
  width: 100%;
  border-bottom: 1px solid var(--color-grey-bg);
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.side__nav_container > ul > li > a {
  text-decoration: none;
  font-family: var(--font-proximanova-light);
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-black1);
  letter-spacing: 1px;
}

.services__select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services__select > a {
  text-decoration: none;
  font-family: var(--font-proximanova-light);
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-black1);
  letter-spacing: 2px;
}

.side__nav__selected {
  font-family: var(--font-proximanova-semibold);
  font-weight: bolder;
}

.animated-text {
  transform: translateX(-50%);
  opacity: 0;
  transition: transform 1s ease, opacity 0.5s ease;
}

.animated-row {
  transform: translateX(50%);
  opacity: 0;
  transition: transform 1.5s ease, opacity 0.5s ease;
}

.animate {
  transform: translateX(0);
  opacity: 1;
}

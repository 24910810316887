.thank-you-section {
  aspect-ratio: auto;
  height: 100vh;
}

#thank-you-msg {
  color: #fff !important;
}

.back-to-home-btn {
  margin-top: 8px;
  display: block;
  outline: none;
  border: 0px;
  padding: 8px 16px;
  text-align: center;
  font-size: 18px;
  margin: 0px auto 0px auto;
  text-decoration: none;
  background-color: #ffffffe0;
  color: #666;
  transition: all 0.3s;
}

.back-to-home-btn:hover {
  background-color: #ffffff80;
  color: #fff;
}

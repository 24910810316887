.app.white {
  background-color: white;
}

.app.black {
  background-color: black;
}

.app {
  transition: background-color 1s ease;
  cursor: default;
}
